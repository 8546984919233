
import { Alert, Form, Accordion, Image, Nav, Navbar, Row, Col, Container, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
import React, { useState, useEffect, useCallback} from "react";
import { useParams} from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import { DebounceInput } from 'react-debounce-input'
import Cookies from 'js-cookie';
import SamImg from "./sam.jpg"
import Flag from 'react-world-flags'
import RangeSlider from 'react-bootstrap-range-slider';
import LoadingIcons from 'react-loading-icons'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => Math.random() * 100),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => Math.random() * 100),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export async function JsonCall(method, url, data, onSuccess, onFail, setLoading) {
  return new Promise((resolve, reject) => {
    return fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: method,
      body: (method == "GET" ? null : JSON.stringify(data)),
    }).then(async (response) => {
      if (response.status == 200) {
        var res = await response.json()
        console.log("success call " + method + " " + url)
        if (onSuccess != undefined) { onSuccess(res); }
        resolve(res)
      } else if (response.status == 404) {
        throw (`URL ${url} not found. Please contact support`)
      } else {
        var resp = await response.json()
        throw (resp.Message)
      }
    }).catch((error) => {
      if (onSuccess != undefined) { onFail(error); }
      console.log(error)
    })
  })
}

export function BottomBar(props) {
  return (
    <div className="fixed-bottom">
      {props.loading ?
        <div className="d-flex justify-content-center m-4">
          <div className="spinner-border text-primary" role="status">
          </div>
        </div> : ""}
      <Alert
        className="m-4"
        variant="success"
        show={props.success != null}>
        <div className="d-flex justify-content-between">
          <div>
            <p>{props.success == null ? "" : props.success.toString()}</p>
          </div>
          <Button variant="close" size="sm" />
        </div>
      </Alert>
      <Alert
        className="m-4"
        variant="info"
        show={props.err != null}>
        <div className="d-flex justify-content-between">
          <div>
            <p>{props.err == null ? "" : props.err.toString()}</p>
          </div>
          <Button variant="close" size="sm" />
        </div>
      </Alert>
    </div>
  );
};


var captcha = ""
function onChange(value) {
  captcha = value
  console.log("Captcha value:", value);
}


function App(props) {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState((Cookies.get('session') != undefined))

  const [surveys, setSurveys] = useState([]);
  const [reload, setReload] = useState(false);

  var completedSurveys = surveys.filter((u) => u.Status == "completed")
  var pendingSurveys = surveys.filter((u) => u.Status == "draft" || u.Status == "pending")
  console.log(completedSurveys)
  console.log(pendingSurveys)
  useEffect(() => {
    setReload(true);
  }, [])

  console.log(Cookies.get('session'))
  console.log(loggedIn)
  if (loggedIn && reload) {
    setReload(false);
    JsonCall("GET", "/api/surveys", {}, (data) => {
      setSurveys(data);
    })
  }


  var Question = (question) => {
    const [value, setValue] = useState(question.AnswerInt == null?0:question.AnswerInt);
    const [text, setText] = useState(question.AnswerString == null?"":question.AnswerString);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    
    return <Row >
      <Col md="8">
      {question.Type == "choiseRange" ?
        <div style={{ "maxWidth": "400px" }}>
          <p>{question.Text[props.locale]}</p>
          <RangeSlider
            tooltip="on"
            tooltipLabel={val => {
              return question.Choices[val][props.locale]
            }
            }
            value={value}
            min={0}
            max={question.Choices.length - 1}
            onAfterChange={() => { setLoading(true); return JsonCall("POST", "/api/answer", {
              Question: question.ID,
              Survey: question.SurveyID,
              AnswerInt: Number(value)
            },
            () => {setLoading(false);setSuccess(true); setFail(false)},
            (err) => {setLoading(false);setSuccess(false); setFail(true)})}}

            onChange={changeEvent => {
              setValue(changeEvent.target.value);
              setSuccess(false);
              setFail(false);
              console.log("CHANGE");
            }}></RangeSlider>
        </div> : <></>}
      {question.Type == "numberRange" ?
        <div style={{ "maxWidth": "400px" }}>
          {question.Text[props.locale]}
          <RangeSlider
            tooltip="on"
            tooltipLabel={val => val + " " + question.Unit[props.locale]}
            value={value}
            min={question.Min}
            max={question.Max}

            onAfterChange={() =>  { setLoading(true); JsonCall("POST", "/api/answer", {
              Question: question.ID,
              Survey: question.SurveyID,
              AnswerInt: Number(value)
            },
            () => {setLoading(false);setSuccess(true); setFail(false)},
            (err) => {setLoading(false);setSuccess(false); setFail(true)})}}

            onChange={changeEvent => {
              setValue(changeEvent.target.value);
              setSuccess(false);
              setFail(false);
              console.log("CHANGE");
            }}></RangeSlider>
        </div>
        : <></>}
      {question.Type == "text" ?
        <div>
          <p>{question.Text[props.locale]}</p>
          <DebounceInput element={TextareaAutosize}
           debounceTimeout={1000}
           forceNotifyByEnter={false}
           value={text}
           onChangeCapture= {() => setSuccess(false)}

           onChange={changeEvent => {
            setText(changeEvent.target.value);
            setSuccess(false);
            setFail(false);
            setLoading(true);
            JsonCall("POST", "/api/answer", {
              Question: question.ID,
              Survey: question.SurveyID,
              AnswerString: text
            },
              () => {setLoading(false);setSuccess(true); setFail(false)},
              (err) => {setLoading(false);setSuccess(false); setFail(true)})
            console.log("CHANGE");
          }}


            className="form-control" maxRows={1000} size="lg" type="text" />
        </div> : <></>
      }
      </Col>
      <Col md="4">
    {loading ?   <LoadingIcons.TailSpin stroke="#000000" strokeOpacity={0.5} speed={1} />: ""}
    {fail ?   <Icon.X className="mx-2 text-danger" size={24}/>: ""}
    {success ? <Icon.CheckCircleFill className="mx-2 text-success" size={24}></Icon.CheckCircleFill> : ""}
    </Col>
    </Row>
  }

  var Survey = (survey) => {
    return <Accordion.Item eventKey={survey.ID}>
      <Accordion.Header>{survey.Name[props.locale]}
        {survey.Status == "completed" ? <Icon.Check className="mx-2 text-success" size={24}></Icon.Check> : <></>}
        {survey.Status == "draft" ? <Icon.Pen className="mx-2 text-warning" size={24}></Icon.Pen> : <></>}
        {survey.Status == "pending" ? <></> : <></>}
      </Accordion.Header>
      <Accordion.Body>
        {survey.Questions == [] ? <></> : survey.Questions.map(u =>  {
        u.SurveyID = survey.ID
        return <Question key={u.ID} {...u} />
        })}
        {/* <Button className="mt-3" onClick={() => console.log("CLICK")}>Complete Survey</Button> */}
      </Accordion.Body>
    </Accordion.Item>
  }

  var Surveys = (props) => {
    const [openKey, setOpenKey] = useState()
    return <>
      <h2>Your Surveys</h2>
      <Accordion flush defaultActiveKey={pendingSurveys.length == 0 ? <></> : pendingSurveys[0].ID} >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Completed Surveys: <span className="mx-2">{completedSurveys.length}</span></Accordion.Header>
          <Accordion.Body>
            <Accordion flush>
              {completedSurveys.map(u => <Survey key={u.ID} {...u} />)}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
          {pendingSurveys.map(u => <Survey key={u.ID} {...u} />)}
      </Accordion>
    </>
  }

  return (<div>
    <React.Fragment>
    </React.Fragment>

    <React.Fragment>
      <Container style={{ font: "Noto Sans Display", background: "#bbbbbb" }}>
        <Row>
          <Navbar style={{ background: "#212529" }} sticky="top">
            <Container>
              <Navbar.Brand className="text-white" href="/">MS Survey{" "}
                <a  href="/survey/en"><Flag className="mx-1" code="gb"  width={32} height={16} /></a>
                <a  href="/survey/be"><Flag className="mx-1" code="by"  width={32} height={16} /></a>
                <a  href="/survey/ru"><Flag className="mx-1" code="ru"  width={32} height={16} /></a></Navbar.Brand>

            </Container>
          </Navbar>
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 1 }} className="justify-content-center" style={{ background: "white", paddingLeft: "50px", paddingRight: "50px" }} >
            
          {props.locale == "be"? <><div className="text-center">
              <Image src={SamImg} fluid></Image>
              <h2>Я патрабую каб <b>ТЫ</b> вылекавал сусвет ад</h2>
              <h2>Рассеяного Склероза</h2>
              <br></br>
            </div>
            <p>Расповяди усем а том что с табой сдаряется</p>
            <ul>
              <li>Зарэгистрируйся</li>
              <li>Уключи Натификации</li>
              <li>Participate in Surveys</li>
              <li>Check out survery results</li>
            </ul>
            <hr></hr>
            <p className="text-center" ><b>MS will not cure itself - only YOUR participation and involvement will do that</b></p>
            </>:
            props.locale == "pl"? <><div className="text-center">
            <Image src={SamImg} fluid></Image>
            <h2>пшпшпшпшпш ПШШ пшпшпшпшпшпшпшш</h2>
            <h2>Рассеяного Склероза</h2>
            <br></br>
          </div>
          <p>Расповяди усем а том что с табой сдаряется</p>
          <ul>
            <li>Зарэгистрируйся</li>
            <li>Уключи Натификации</li>
            <li>Participate in Surveys</li>
            <li>Check out survery results</li>
          </ul>
          <hr></hr>
          <p className="text-center" ><b>MS will not cure itself - only YOUR participation and involvement will do that</b></p>
          </>:
            props.locale == "ru"? <><div className="text-center">
            <Image src={SamImg} fluid></Image>
            <h2>Я хочу чтобы <b>ТЫ</b> вылечил свет от</h2>
            <h2>Рассеяного Склероза</h2>
            <br></br>
          </div>
          <p>Расскажи всем что с тобой происходит</p>
          <ul>
            <li>Зарегистрируйся</li>
            <li>Включи уведомления</li>
            <li>Учавствуй в опросах</li>
            <li>Изучай результаты исследований</li>
          </ul>
          <hr></hr>
          <p className="text-center" ><b>РС сам себя не вылечит - только ТВОЕ участвие и вовлеченность сделают это</b></p>
          </>:
            <><div className="text-center">
            <Image src={SamImg} fluid></Image>
            <h2>I want <b>YOU</b> to cure the world from</h2>
            <h2>Multiple Sclerosis</h2>
            <br></br>
          </div>
          <p>Clicking a few buttons will not kill you. MS will. Don't let your life be wasted with no impact.</p>
          <ul>
            <li>Sign up</li>
            <li>Enable Notifications</li>
            <li>Participate in all Surveys</li>
          </ul>
          <hr></hr>
          <p className="text-center" ><b>MS will not cure itself - only YOUR participation and involvement will do that</b></p>
          </>}
          <hr></hr>
          <br></br>
          <br></br>


            {
              !loggedIn ?
                <>
                  <Row className="justify-content-md-center">
                  <Col md={6}>
                  <Form onSubmit={(evt) => {
                    evt.preventDefault();
                    console.log(evt);
                    JsonCall("POST", "/api/signin", {password: password, phone: phone}, (data) => {
                      setReload(true);
                      setLoggedIn(true);
                      // setRefreshReCaptcha(r => !r);
                    })
                  }}>
                    <h4>Login / Register</h4>
                        <Form.Group className="mb-2" controlId="phone">
                          <Form.Text>Phone Number</Form.Text>
                          <Form.Control type="tel" name="ms_pass" placeholder="+375123456789" onChange={(e) => setPhone(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="password">
                        <Form.Text>Password</Form.Text>
                          <Form.Control type="password" name="ms_pass" placeholder="******" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                    <h6>By clicking button below I agree with MS Survey <a href={"https://www.termsfeed.com/live/7b37ee3d-7e45-4bb9-8820-75d9f73dd25c"}>Privacy Policy, </a> </h6>
                    <h6>understand that my answers to this survey will be available online  </h6>
                    <h6>and agree to receive SMS notification about new surveys</h6>
                        <Button m={2} variant="primary" type="submit">Agree and Continue </Button>
                  </Form></Col>

                  {/* <Col md={6}>
                  <Form onSubmit={(evt) => {
                    evt.preventDefault();
                    console.log(evt);
                    JsonCall("POST", "/api/signin", {password: password, phone: phone}, (data) => {
                      setReload(true);
                      setLoggedIn(true);
                      // setRefreshReCaptcha(r => !r);
                    })
                  }}>
                    <h4>Register</h4>
                        <Form.Group className="mb-2" controlId="phone">
                          <Form.Text>Phone Number</Form.Text>
                          <Form.Control type="tel" name="ms_pass" placeholder="+375123456789" onChange={(e) => setPhone(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="password">
                        <Form.Text>Password</Form.Text>
                          <Form.Control type="password" name="ms_pass" placeholder="******" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="agreed">
                          <Form.Check required type="checkbox" name="agreed" label={(<>I have read and agree with MS Survey <a href={"https://www.termsfeed.com/live/7b37ee3d-7e45-4bb9-8820-75d9f73dd25c"}>Privacy Policy </a></>)}></Form.Check>
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="agreed2">
                          <Form.Check required type="checkbox" name="agreed2" label="I understand that my answers for this survey will be available online in anonymous form."></Form.Check>
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="agreed3">
                          <Form.Check  type="checkbox" name="agreed3" label="I want to be notified about new surveys via SMS"></Form.Check>
                        </Form.Group>
                        <Form.Group>
                        </Form.Group>
                        <Button m={2} variant="primary" type="submit"> Sign up </Button>
                  </Form></Col> */}
                  </Row>
                </> :
                <>
                  <Surveys surveys={surveys}></Surveys>
                </>
            }



            <br></br>
            <br></br>
            {/* <h2>Explore survey answers</h2>
            <Bar options={options} data={data} /> */}
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>


          </Col>
        </Row>
      </Container>
    </React.Fragment>
  </div>
  );
}

export default App;
